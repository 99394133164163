.Layout {
  background-color: #183c44;
  height: 100%;
}

.Content {
  margin-top: 0px;
  background-color: #183c44;
  color: #7fffd4;
  height: 100%;
  padding: 250px 10% 250px 20%;
  transition: 0.5s ease-in;
}

h2::before {
  counter-increment: h2-counter;
  content: '0' counter(h2-counter) '. ';
  color: #7fffd4;
  margin-top: 2px;
  font-size: 1.1rem;
  margin-right: 10px;
}

h2::after {
  content: '';
  display: inline-block;
  height: 2px;
  width: 100%;
  background-color: rgb(48, 60, 85);
  position: relative;
  top: -5px;
}

@media (max-width: 702px) {
  .Content {
    padding: 100px 0;
  }
}
