.Card {
  position: relative;
  border: 1px solid grey;
  box-shadow: 0 1px 9px grey;
  padding: 4px;
  height: 400px;
  min-width: 250px;
  z-index: 1;
  margin-bottom: 50px;
  animation: slide-up 0.6s ease;
  max-width: 700px;
}

.Card > .Feature {
  font-size: 0.9rem;
  position: absolute;
  right: 45px;
  top: 30px;
  color: #7fffd4;
}

.Card > .ProjectName {
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  right: 45px;
  top: 60px;
  color: #fff;
}

.Card > .Description {
  padding: 14px;
  font-size: 1rem;
  position: absolute;
  right: 45px;
  top: 140px;
  color: rgb(204, 214, 246);
  max-width: 80%;
  box-shadow: 0 1px 9px grey;
  background-color: #183c44;
}

.Card > .Description > ul {
  display: grid;
  grid-template-columns: repeat(3, minmax(140px, 200px));
  overflow: hidden;
  padding: 0px;
  margin: 20px 0px 0px;
  list-style: none;
  font-size: 0.85rem;
}

.Card > .Link {
  font-size: 1.6rem;
  position: absolute;
  right: 45px;
  bottom: 25px;
  color: #7fffd4;
}

.Card > .Link > a {
  text-decoration: none;
  color: rgb(204, 214, 246);
}

.Card > .Link > a > em {
  margin-left: 15px;
}

@media (max-width: 980px) {
  .Card {
    height: 500px;
    padding-left: 10px;
  }
  .Card > .ProjectName {
    font-size: 1.8rem;
    padding-left: 10px;
  }
  .Card > .Description {
    right: 20px;
  }
  .Card > .Description > ul {
    display: grid;
    grid-template-columns: repeat(3, minmax(80px, 200px));
    overflow: hidden;
    padding: 0px;
    margin: 20px 0px 0px;
    list-style: none;
    font-size: 0.85rem;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
