.SideDrawer {
  background-color: #427b73;
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

@media (min-width: 702px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(100%);
}

.Logo {
  height: 11%;
  margin-bottom: 32px;
}
