.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  flex-flow: row;
  font-size: 0.9rem;
  display: inline-flex;
}

.TextColor {
  color: #c8cfd0 !important;
}

@media (max-width: 702px) {
  .NavigationItems {
    flex-flow: column;
    display: block;
  }
}
