.SocialLinks {
  display: block;
  position: fixed;
  bottom: 0px;
  left: 30px;
  color: #7fffd4;
  padding: 15px 20px 0 20px;
  opacity: 0.8;
  animation: slide-in 0.6s ease;
}

.SocialLinks > ul > li {
  list-style-type: none;
  font-size: 1.2rem;
  padding-bottom: 15px;
}

.SocialLinks > ul > li > a {
  text-decoration: none;
  color: rgb(204, 214, 246);
}

.SocialLinks ul::after {
  content: '';
  display: block;
  width: 1px;
  height: 140px;
  background-color: rgb(168, 178, 209);
  margin: 0px auto;
}

@media screen and (max-width: 702px), screen and (max-height: 500px) {
  .SocialLinks {
    position: relative;
    bottom: initial;
    left: initial;
    background-color: #183c44;
    margin: 0;
    opacity: 1;
  }

  .SocialLinks > ul {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
  }

  .SocialLinks > ul > li {
    font-size: 1.2rem;
  }
  .SocialLinks ul::after {
    display: none;
  }
}

@media (min-width: 703px) {
  @keyframes slide-in {
    0% {
      opacity: 0;
      transform: translateX(-120px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
