.ScrollToTop {
  position: fixed;
  bottom: 120px;
  right: 50px;
  color: #7fffd4;
  border: 1px solid #7fffd4;
  padding: 15px 20px;
  border-radius: 4px;
  opacity: 0.8;
  z-index: 500;
  animation: slide-in 0.6s ease;
}

.ScrollToTop:hover {
  transition: 0.5s;
  background-color: rgba(127, 255, 212, 0.09);
}

.Hidden {
  display: none;
}

@media (max-width: 702px) {
  .ScrollToTop {
    padding: 10px 15px;
    right: 15px;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
