.AboutMe {
  padding: 150px 100px 0 100px;
  color: rgb(204, 214, 246);
  animation: slide-in 0.6s ease;
}

.Heading {
  color: #fff;
}

.Description {
  margin-top: 30px;
  max-width: 500px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.GridList {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  overflow: hidden;
  padding: 0px;
  margin: 20px 0px 0px;
  list-style: none;
}

.GridListItem {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
}

.GridListItem::before {
  content: '»';
  position: absolute;
  left: 0px;
  color: rgb(100, 255, 218);
  font-size: 14px;
  line-height: 12px;
  padding-top: 4px;
}

@media (max-width: 702px) {
  .AboutMe {
    padding: 0 50px;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
