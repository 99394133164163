.Introduction {
  padding: 0 100px;
  margin-bottom: 200px;
  animation: slide-in 0.6s ease;
}

.Heading {
  margin-bottom: 15px;
}

.Description {
  margin-top: 30px;
  max-width: 500px;
  line-height: 1.5;
  margin-bottom: 30px;
  color: rgb(204, 214, 246);
}

@media (max-width: 702px) {
  .Introduction {
    margin-bottom: 100px;
    padding: 0 50px;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
